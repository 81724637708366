<template>
  <div>
    <section class="nav-wrapper" :class="{ fixed: isNavFixed }">
      <nav :class="{ 'hide-nav': foldNav }">
        <h1 class="logo-wrap" @click="updateNav(0, true)">
          <router-link id="logo" to="/en/#intro"
            ><img alt="Recreate" src="../assets/img/Recreate.svg"
          /></router-link>
        </h1>
        <!--        <p class="nav-right"><a class="search icon-salient-search"></a></p>-->
        <div class="normal-nav">
          <p class="nav-right"><router-link to="/zh">中文</router-link></p>
          <ul class="nav-items">
            <li
              class="nav-item"
              @click="updateNav(0, true)"
              :class="{ active: activatedNavItem === 0 }"
            >
              <router-link to="/en/#intro">Intro</router-link>
            </li>
            <li
              class="nav-item"
              @click="updateNav(1, true)"
              :class="{ active: activatedNavItem === 1 }"
            >
              <router-link to="/en/#about">About</router-link>
            </li>
            <li
              class="nav-item"
              @click="updateNav(2, true)"
              :class="{ active: activatedNavItem === 2 }"
            >
              <router-link to="/en/#careers">Careers</router-link>
            </li>
            <li
              class="nav-item"
              @click="updateNav(3, true)"
              :class="{ active: activatedNavItem === 3 }"
            >
              <router-link to="/en/#contact">Contact</router-link>
            </li>
          </ul>
        </div>

        <div class="nav-btn" @click="toggleMobileNav($event)">
          <a class="btn-lines"></a>
        </div>
      </nav>

      <div class="mobile-nav" :class="{ show: isShowMobileNav && foldNav }">
        <ul class="mobile-nav-list">
          <li class="mobile-nav-item" @click="updateNav(0, true)">
            <router-link to="/en/#intro">Intro</router-link>
          </li>
          <li class="mobile-nav-item" @click="updateNav(1, true)">
            <router-link to="/en/#about">About</router-link>
          </li>
          <li class="mobile-nav-item" @click="updateNav(2, true)">
            <router-link to="/en/#careers">Careers</router-link>
          </li>
          <li class="mobile-nav-item" @click="updateNav(3, true)">
            <router-link to="/en/#contact">Contact</router-link>
          </li>
          <li class="mobile-nav-lang">
            <router-link to="/zh">中文</router-link>
          </li>
        </ul>
      </div>
    </section>

    <router-view
      :careers="careers"
      :navClicked="isClickedTrigger"
      :currentNav="activatedNavItem"
      v-on:update-scrolltops="updateScrollTops"
      v-on:child-mounted="loaded"
      v-on:update-nav="updateNav"
      v-on:reset-nav-clicked-trigger="resetClickedTrigger"
    >
    </router-view>

    <footer>
      <div class="footer-con content">
        <div>
          © {{ new Date().getFullYear() }} Recreate Games. Source Technology
          Inc. All Rights Reserved.
        </div>
        <!-- <div>
          <img alt="" src="../assets/img/beian.png" class="beian-img" />
          <a href="beian.miit.gov.cn" target="_blank">沪ICP备2022007403号-1</a>
        </div> -->
      </div>
    </footer>
  </div>
</template>

<script>
import { scrollTop } from "@/utils/utils";
export default {
  name: "AppEn",
  data() {
    return {
      isShowMobileNav: false,
      foldNav: false,
      isNavFixed: false,
      activatedNavItem: 0,
      isClickedTrigger: false,
      navs: ["#intro", "#about", "#careers", "#contact"],
      scrollingBinder: null,
      careers: [
        /*        {
          id: 0,
          position: 'Executive Game Producer',
          place: 'Shanghai, China',
          time: 'fulltime',
          descri: '',
          responsibility: 'Define game features to target different platforms\nManage developing progress',
          required: 'Have a passion for video games\nExperience with games porting, able to push project forward independently\nDeep understanding of at least one of the following platforms: PC, Console, Mobile\nAble to define and optimize game features based on different platform experiences\nStrong communications skills, excellent organization and management skills\nAbility to work under pressure',
          plus: 'Experience with a complete game development process\nExperience with game development management',
          materials: 'Resume'
        },*/
        {
          id: 1,
          position: "Concept Art Director",
          place: "Shanghai, China",
          time: "fulltime",
          descri: "",
          responsibility:
            "Responsible for visual style and concept design of the game\nDirect the concept design team and ensure team productivity\nResponsible for concept look-up,concept design, and integration of concept art assets\nWork with game producer and other team directors to ensure game quality\nProvide feedback and career advice for concept art team members\nCollaborate with other directors to develop and maintain game’s unique visual style",
          required:
            "Solid fundamentals in art\nPerceptive to color, lighting, and texture\nStrong imagination and high aesthetic standards\nStrong communications skills and organizational management skills\nAbility to work under pressure\nExperience with a complete game development process",
          plus: "",
          materials: "Resume\nPersonal portfolio",
        },
        {
          id: 2,
          position: "Senior Character Concept Artist",
          place: "Shanghai, China",
          time: "fulltime",
          descri: "",
          responsibility:
            "Define game character style; produce character concept drawings\nCreate the concept design according to the art style set by Concept Art Director\nWork with 3D artists to create character assets in game\nProduce high-quality 3D character according to project needs\nFollow industry trends; ensure art quality",
          required:
            "Familiar with animal anatomy\nSolid fundamentals in art; perceptive to color, lighting, and texture; high aesthetic standards\nAbility to learn art-related softwares fast\nAbility to collaborate and strong sense of responsibility\nAbility to design stylized cartoon characters",
          plus:
            "Experience with a complete game development process\nFamiliar with at least one 3D software",
          materials: "Resume\nPersonal portfolio",
        },
        {
          id: 3,
          position: "Outsource Manager",
          place: "Shanghai, China",
          time: "fulltime",
          descri: "",
          responsibility:
            "Coordinate with outsource artists and studios; push project progress\nManage risks with outsource artists and studios；solve problems during outsource production process",
          required:
            "Experience with game or Internet-related project management\nAbility to understand game art styles and communicate with outsource teams\nUnderstand game art production process and provide effective feedbacks to outsource teams\nStrong communications skills and excellent organization and management skills\nAbility to work under pressure",
          plus:
            "Experience with one or more outsource management project in game or film industry",
          materials: "Resume",
        },
        {
          id: 4,
          position: "3D Artist Director",
          place: "Shanghai, China",
          time: "fulltime",
          descri: "",
          responsibility:
            "Collaborate with Concept Art Director; direct 3D artist team to produce 3D assets loyal to the concept designs\nResponsible for the management of 3D artist team; set and push asset production disciplines\nResponsible for the final visual effect in game\nEnsure style consistency in characters and environment asset styles\nFollow industry trends; maintain high artistic standards\nWork with the engineer team to optimize game performance\nProvide feedback and career advice to 3D artist team members",
          required:
            "Familiar with the entire 3D asset production process\nAbility to push art and technical art team to fulfill target effects\nFamiliar with UNITY or UNREAL ENGINE; able to use art-related softwares proficiently\nWilling to explore new art styles; Independent and unique understanding of art\nFamiliar with 3D asset production process (modeling, bundling, rendering, and etc)\nExperience with a complete game development process",
          plus: "",
          materials: "Resume\nPersonal portfolio",
        },
        {
          id: 5,
          position: "Art Asset Management",
          place: "Shanghai, China",
          time: "fulltime",
          descri: "",
          responsibility:
            "Work with Art Directors to optimize art assets production pipeline\nWork with Concept Art Director, other art directors and the art team to set important milestones, objectives and requirements\nAssist with Concept Art Director to set schedule and priority\nCoordinate communication in art teams and synchronize work progress\nMaintain art team schedule, and ensure art assets are delivered on time\nAssist art teams to solve tools and pipelines related problems",
          required:
            "Familiar with basic 3D softwares\nFamiliar with game or film production process\nAbility to promote art, technical art, and other teams to achieve target effects\nAbility to collaborate; strong sense of responsibility\nAbility to learn art-related softwares fast",
          plus: "Experience with a complete game or film production process",
          materials: "Resume",
        },
        {
          id: 6,
          position: "Senior Environment Concept Artist",
          place: "Shanghai, China",
          time: "fulltime",
          descri: "",
          responsibility:
            "Work with Concept Art Director to define visual style and effects in game; produce clear, detailed and executable concept design\nDesign and produce environment concept according to set game style\nProduce environment concept and design objects",
          required:
            "Great imagination and strong ability to express environment and atmosphere\nGreat sense in color, lighting and space in environment design\nAbility to turn gameplay requirements into visual design\nCollect, research references and produce detailed sketch\nAbility to collaborate; strong sense of responsibility\nAbility to learn art-related softwares fast",
          plus:
            "Experience with a complete game development process\nFamiliar with at least one 3D software",
          materials: "Resume\nPersonal portfolio",
        },
        {
          id: 7,
          position: "Senior 3D Artist",
          place: "Shanghai, China",
          time: "fulltime",
          descri: "",
          responsibility:
            "Create art assets, from concept to final implementation in Unity\nCreate in-game ready, real-time 3D environment assets",
          required:
            "Strong aesthetic sense\nDeep understanding of lighting and material\nHighly skilled one of 3D softwares.\nCan independently finish environment model creation, UV edit, material creation, texture baking\nFamiliar with Unity or Unreal Engine\nStrong debug ability and learning ability\nCan fill in the gaps within the concept art and produce assets that are true to the concept",
          plus:
            "Experience with a complete console game development process\nExperience with shader production",
          materials: "Resume\nPersonal portfolio",
        },
        {
          id: 8,
          position: "3D Animator",
          place: "Shanghai, China",
          time: "fulltime",
          descri:
            "A game needs to be convincing across the timeline, the key of which is animations. No matter it is characters, objects, kinetics or particle effects, good animations can even make a teapot look alive. We hope you understand all kinds of classic expressions of animations, and express it with exceptional skills. This position not only requires you to utilize the animation skills you acquired from school or previous works, we also want you to make the animations better and the work more efficient with more advanced techniques.",
          responsibility:
            "Create 2D/3D storyboards for early stages\nCreate 2D/3D character/object animations\nCreate rigging of 3D objects, and discuss the viability with engineer team\nParticipate in the motion capture processes",
          required:
            "A passion for gaming\nFamiliar with linear and non-linear creation styles of animation\nFamiliar with the bindings of human/animal skeletons\nKnows how to deal with common technical problems such as volume collapse and expansion\nUnderstand state machine and mixed engine action mechanism\nStrong self-motivation and self-learning skills",
          plus:
            "Took part in the complete creation process of an AAA game\nFamiliar with Unity or Unreal engines\nFamiliar with the optical or inertial motion capture process\nFamiliar with the particle or dynamics systems with any 3D tools",
          materials: "Resume",
        },
        {
          id: 9,
          position: "Web Frontend Engineer (Interactive Streaming Platform)",
          place: "Shanghai, China",
          time: "fulltime",
          descri: "",
          responsibility: "",
          required:
            "Bachelor’s degree or above in computer science or related fields, and 2+ years of frontend development experience is preferred\nProficient with HTML5, CSS3, or JavaScript and have a good understanding of web standards\nThorough understanding of React\nProficient with Node.js or other backend development languages and frameworks\nExperience in designing & developing mid-sized frontend projects independently\nStrong verbal and written communication skills, and collaborates well in a team environment",
          plus:
            "Familiar with at least one of the following programming languages: Java, Go, Python\nFamiliar with real-time communication technologies such as WebRTC\nFamiliar with common network protocols such as RTMP, RTSP, HLS, etc.\nStrong technology and product sense. Good at innovating",
          materials: "Resume",
        },
        {
          id: 10,
          position: "Web Backend Engineer (Interactive Streaming Platform)",
          place: "Shanghai, China",
          time: "fulltime",
          descri: "",
          responsibility: "",
          required:
            "Bachelor’s degree or above in computer science or related technical fields, and 2+ years of backend development experience is preferred\nProficient with more than two mainstream programming languages: Go, C++, Java\nFamiliar with any database(SQL/MongoDB/Redis) system, understand its disaster tolerance principle, and able to fine-tune the performance\nExperience with popular public cloud services both domestic and international: Aliyun, AWS, GCP, etc.\nClose attention to code quality and R&D specifications\nStrong verbal and written communication skills, strong analytical thinking and troubleshooting skills, and great team player",
          plus:
            "Participated in programming competition such as ACM-ICPC\nFamiliar with one or more scripting languages, and able to build complex systems\nFamiliar with video codec and related technologies\nFamiliar with real-time communication technologies such as WebRTC\nExperience with OpenStack, LXD, runC, Kubernetes, cloud native stack",
          materials: "Resume",
        },
        {
          id: 11,
          position: "Client Engineer (Interactive Streaming Platform)",
          place: "Shanghai, China",
          time: "fulltime",
          descri: "",
          responsibility: "",
          required:
            "Bachelor’s degree or above in computer science or related technical fields\nProficient with C++/C#，able to learn and use frameworks such as QT for client development\nKnow the general principles of video encoding and decoding, and able to learn and use libraries such as FFmpeg\nClose attention to code quality and R&D specifications\nStrong verbal and written communication skills, strong analytical thinking and troubleshooting skills, and great team player",
          plus:
            "Participated in programming competition such as ACM-ICPC\nExperienced with medium or large sized projects development using QT and multimedia software\nDeep understanding of video coding and decoding\nFamiliar with real-time communication technologies such as WebRTC",
          materials: "Resume",
        },
      ],
      resizeBinder: null,
      scrollTops: [],
    };
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.scrollingBinder, false);
    window.removeEventListener("resize", this.resizeBinder, false);
    document.removeEventListener("click", this.hideMobileNav, false);
  },
  mounted() {
    this.resizeBinder = this.foldMobileNav.bind(this);
    this.scrollingBinder = this.pageScrolling.bind(this);
    document.addEventListener("scroll", this.scrollingBinder, false);
    window.addEventListener("resize", this.resizeBinder, false);
    document.addEventListener("click", this.hideMobileNav, false);
    this.foldMobileNav();
  },
  methods: {
    toggleMobileNav(e) {
      e.stopPropagation();
      this.isShowMobileNav = !this.isShowMobileNav;
    },
    hideMobileNav() {
      if (this.isShowMobileNav) this.isShowMobileNav = false;
    },
    foldMobileNav() {
      this.foldNav = window.innerWidth <= 800;
    },
    pageScrolling() {
      let _scrollTop = scrollTop();
      if (_scrollTop > 0 && !this.isNavFixed) {
        this.isNavFixed = true;
      } else if (_scrollTop === 0 && this.isNavFixed) {
        this.isNavFixed = false;
      }
    },
    loaded() {
      let itemId = this.navs.findIndex((hash) => hash === this.$route.hash);
      if (itemId > 0) {
        this.scrollTo(this.scrollTops[itemId]);
      }
    },
    updateScrollTops(arr) {
      this.scrollTops = arr;
    },
    scrollTo(y) {
      document.documentElement.scrollTop = document.body.scrollTop = y;
    },
    updateNav(itemId, isClicked) {
      this.isClickedTrigger = isClicked || false;
      this.activatedNavItem = itemId;
      if (isClicked && this.$route.hash === this.navs[itemId]) {
        this.scrollTo(this.scrollTops[itemId]);
      }
    },
    resetClickedTrigger() {
      this.isClickedTrigger = false;
    },
  },
};
</script>
