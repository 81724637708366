import Vue from 'vue';
import Router from 'vue-router';
import AppEn from "@/components/AppEn";
import AppZh from './components/AppZh';
import HomeEn from './components/HomeEn.vue';
import HomeZh from "@/components/HomeZh";
import CareerEn from "@/components/CareerEn";
import CareerZh from "@/components/CareerZh";
import Error from './components/Error';

Vue.use(Router);
const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	linkActiveClass: '',
	linkExactActiveClass: '',
	routes: [
		{
			path: '/',
			name: 'home',
			redirect: () => {
				return `/${getDefaultLang()}`;
			}
		},
		{
			path: '/zh',
			component: AppZh,
			beforeEnter: (to, from, next) => {
				setLang('zh');
				next()
			},
			children: [
				{
					path: '/',
					name: 'home-zh',
					component: HomeZh,
				},
				{
					path: 'career/:career',
					name: 'career-zh',
					component: CareerZh,
				},
				{
					path: 'error',
					component: Error,
				}
			]
		},
		{
			path: '/en',
			component: AppEn,
			beforeEnter: (to, from, next) => {
				setLang('en');
				next();
			},
			children: [
				{
					path: '/',
					name: 'home-en',
					component: HomeEn
				},
				{
					path: 'career/:career',
					name: 'career-en',
					component: CareerEn,
				},
				{
					path: 'error',
					component: Error,
				}
			]
		},
		{
			path: '*',
			name: "NotFound",
			redirect: () => {
				return `/${getDefaultLang()}/error`;
			}
		}
	],
/*	scrollBehavior( to, from, savedPosition ) {
		if( new RegExp( from.path.replace(/\/$/, '') + '(/)?$').test(to.path) ){
			document.documentElement.style.scrollBehavior = 'smooth';
			if( to.hash ) {
				console.log('to hash', to.hash)
				return {selector: to.hash}
			}
			
			return { x: 0, y: 0 };
		}
		setAutoScrollBehavior();
		if( savedPosition ){
			return savedPosition;
		}
		console.log(111)
		return { x: 0, y: 0 };
	},*/
	scrollBehavior( to, from, savedPosition ) {
		if(to.name === from.name){
			document.documentElement.style.scrollBehavior = 'smooth';
		}else{
			setAutoScrollBehavior();
			if(to.hash ){
				return {selector: to.hash}
			}
		}
		document.documentElement.clientHeight;
		if(savedPosition){
			return savedPosition;
		}
		return { x: 0, y: 0 };
	},
});

router.onError(() => {
	this.push({path: `/${getDefaultLang()}/error`});
});

function setAutoScrollBehavior () {
	document.documentElement.style.scrollBehavior = 'initial';
}


function getDefaultLang(){
	let preferredLang = localStorage.getItem('preferredLang') || navigator.language || navigator.userLanguage || navigator.browserLanguage || 'en';
	preferredLang = preferredLang.toLowerCase();
	preferredLang = preferredLang.substr(0, 2);
	if(!/(en)|(zh)/.test(preferredLang)){
		// console.log('navigator language is not supported');
		preferredLang = 'en';
	}
	return preferredLang;
}
function setLang(lang){
	let localLang = localStorage.getItem('preferredLang');
	if(!localLang || localStorage !== lang){
		localStorage.setItem('preferredLang', lang);
	}
	document.documentElement.lang = lang;
	setAutoScrollBehavior();
}


export default router;