<template>
  <main>
    <section class="intro" id="intro">
      <div class="bg-overlay">
        <video
          ref="video"
          preload="auto"
          loop
          autoplay
          playsinline
          muted
          :style="{ left: videoLeft }"
        >
          <source src="../assets/img/T022.mp4" type="video/mp4" />
        </video>
      </div>
      <div class="intro-con content">
        <h1 class="headline-l">
          Recreate Games <br />
          Make it Amazing.
        </h1>
      </div>
    </section>

    <section class="about " id="about">
      <div class="content">
        <h1 class="headline-s">
          WE ARE PASSIONATE ABOUT<br />CREATING REALISTIC GAMES <br />THAT ARE
          IMMERSIVE AND MEMORABLE.
        </h1>
        <p class="paragraph">
          Recreate games is a game studio owned by Source Technology with the
          goal of creating next-gen immersive entertainment products. Our
          investors include Matrix Partners, Bertelsmann and Zhen Fund.
        </p>
        <p class="paragraph">
          We pursue a small-team working approach. We believe that small teams
          can generate tremendous creative energy, and that the greatness of a
          product is not determined by team size, but by proficiency, enthusiasm
          and groundbreaking ideas.
        </p>
        <!--      <p class="btn-wrap"><a class="btn-black"><span class="btn-txt">Learn More</span><i class="arrow-right"></i></a></p>-->
      </div>
      <section class="about-2">
        <section class="content">
          <p class="headline-s">
            Recreate next generation entertainment products
          </p>
        </section>
      </section>
    </section>

    <section class="careers" id="careers">
      <section class="content">
        <h1 class="headline-l">Careers</h1>
        <ul class="job-list">
          <li
            class="job-item"
            v-for="(career, i) in careers"
            :key="`career-${i}`"
          >
            <span class="job-item-btn"><a class="btn-black">Details</a></span>
            <div class="job-item-info">
              <span class="job-name">{{ career.position }}</span>
              <span>{{ career.place }}</span>
              <span>{{ career.time }}</span>
            </div>

            <a
              class="job-link"
              :href="`/en/career/${career.id}`"
              target="_blank"
            ></a>
          </li>
        </ul>
      </section>
    </section>

    <section class="location">
      <div class="content">
        <h1 class="headline-s">Contact Us</h1>
      </div>
      <div id="map" class="location-map"></div>
    </section>

    <section class="contact" id="contact">
      <div class="content">
        <ul class="contact-info">
          <li class="contact-item">
            <h2 class="headline-xs">Player Support</h2>
            <p class="contact-title">
              <a href="mailto:Support@recreate.games">Support@recreate.games</a>
            </p>
            <p class="contact-desc">All game related questions are welcome</p>
          </li>
          <li class="contact-item">
            <h2 class="headline-xs">Business</h2>
            <p class="contact-title">
              <a href="mailto:Business@recreate.games"
                >Business@recreate.games</a
              >
            </p>
            <p class="contact-desc">Work with us in a partnership</p>
          </li>
          <li class="contact-item">
            <h2 class="headline-xs">Employment</h2>
            <p class="contact-title">
              <a href="mailto:Careers@recreate.games">Careers@recreate.games</a>
            </p>
            <p class="contact-desc">Join us to create top-notch games</p>
          </li>
          <li class="contact-item">
            <h2 class="headline-xs">Media Report</h2>
            <p class="contact-title">
              <a href="mailto:Media@recreate.games">Media@recreate.games</a>
            </p>
            <p class="contact-desc">Press and media contact</p>
          </li>
        </ul>
        <div class="msg-form">
          <form>
            <p class="input-wrap">
              <label for="name" class="label">Your Name ( required )</label>
              <input
                v-model.trim="userName"
                @blur="invalidationNameCheck()"
                :class="{ warn: invalidation.name }"
                class="input"
                autocomplete="off"
                id="name"
                type="text"
                required
                placeholder="Your Name"
              />
            </p>
            <p class="input-wrap">
              <label for="email" class="label"
                >Your Email Address ( required )</label
              >
              <input
                v-model.trim="emailAddr"
                @blur="invalidationEmailCheck()"
                :class="{ warn: invalidation.email }"
                class="input"
                autocomplete="off"
                id="email"
                type="text"
                required
                placeholder="Your Email"
              />
            </p>
            <p class="input-wrap">
              <label for="subject" class="label">Subject</label>
              <input
                v-model.trim="subject"
                autocomplete="off"
                id="subject"
                type="text"
                class="input"
                placeholder="Subject"
              />
            </p>
            <p class="input-wrap">
              <label for="msg" class="label">Your Message ( required )</label>
              <textarea
                v-model.trim="message"
                :class="{ warn: invalidation.message }"
                class="input"
                @blur="invalidationMsgCheck()"
                autocomplete="off"
                id="msg"
                placeholder="Your Message"
              ></textarea>
            </p>
            <div class="input-wrap">
              <p :class="stateOfRequest" class="btn-submit" @click="send()">
                <!--@click="send() testSend()"-->
                <span class="txt">Send</span>
                <span class="loading-icon"
                  >Sending<i class="loading-ico"></i
                ></span>
                <span class="done-icon">Done<i class="done-ico"></i></span>
                <span class="error-icon">Error<i class="error-ico"></i></span>
              </p>
              <span class="error-notice"></span>
              <p class="form-notice">
                <span
                  :class="{ show: isShowNotice && stateOfRequest === 'error' }"
                  class="notice notice-error"
                  >Something went wrong. Please try again later.</span
                >
                <span
                  :class="{ show: isShowNotice && stateOfRequest === 'done' }"
                  class="notice notice-success"
                  >Thank you for your message.</span
                >
                <!--                <span class="notice-warn">One or more fields have an error. Please check and try again.</span>-->
                <!--                <span class="notice-warn">The file is too big.</span>-->
              </p>
            </div>
          </form>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { sendEmail } from "@/api";
import { emailRegExp, googleLoader, scrollTop, styledMap } from "@/utils/utils";

export default {
  name: "HomeEn",
  data() {
    return {
      videoLeft: 0,
      userName: "",
      emailAddr: "",
      subject: "",
      message: "",
      isShowNotice: false,
      pending: false, // pending request of sending email
      invalidation: {
        name: false,
        email: false,
        message: false,
      },
      stateOfRequest: "", // '', 'loading', 'error', 'done'

      activatedNav: 0,
      scrollTops: [],
      halfWindowHeight: 0,
      scrollDebounceBinder: null,
      scrollThrottleBinder: null,
      getOffsetTopBinder: null,
      videoPosBinder: null,
    };
  },
  props: {
    careers: Array,
    navClicked: Boolean,
    currentNav: Number,
  },
  rou(to, from, next) {
    console.log("before router enter", to, from);
    // return to;
    next();
  },
  /*  beforeRouteUpdate( to, from, next) {
    console.log('before router enter', to, from)
    // return to;
    next();
  },*/
  beforeDestroy() {
    document.removeEventListener("scroll", this.scrollDebounceBinder, false);
    document.removeEventListener("scroll", this.scrollThrottleBinder, false);
    window.removeEventListener("resize", this.getOffsetTopBinder, false);
    window.removeEventListener("resize", this.videoPosBinder, false);
  },
  mounted() {
    this.scrollDebounceBinder = this.scrollDebounce(this.scrollHandle, 300);
    this.scrollThrottleBinder = this.scrollThrottle(
      this.activeNavItem,
      300
    ).bind(this);
    this.getOffsetTopBinder = this.getOffsetTop.bind(this);
    this.videoPosBinder = this.setVideoPos.bind(this);
    this.getOffsetTop();
    document.addEventListener("scroll", this.scrollDebounceBinder, false);
    document.addEventListener("scroll", this.scrollThrottleBinder, false);
    window.addEventListener("resize", this.getOffsetTopBinder, false);
    window.addEventListener("resize", this.videoPosBinder, false);
    this.mapInit();
    this.$refs.video.addEventListener("canplay", () => {
      this.setVideoPos();
    });
    this.$emit("child-mounted");
  },
  methods: {
    setVideoPos() {
      let winWidth = window.innerWidth;
      let videoWidth = this.$refs.video.clientWidth;
      this.videoLeft = (winWidth - videoWidth) / 2 + "px";
    },
    mapInit() {
      // google map
      // const loader = new Loader( googleKey );
      const myLatLng = {
        lat: 31.299528299169058, //39.997313,
        lng: 121.51672754091616, //116.480064,
      };
      googleLoader.load().then(function(google) {
        const styledMapType = new google.maps.StyledMapType(styledMap, {
          name: "Styled Map",
        });
        const map = new google.maps.Map(document.getElementById("map"), {
          center: myLatLng,
          zoom: 13,
          fullscreenControl: false,
          zoomControl: true,
          mapTypeControlOptions: {
            mapTypeIds: [
              "roadmap",
              "satellite",
              "hybrid",
              "terrain",
              "styled_map",
            ],
          },
          scrollwheel: false,
          panControl: false,
          disableDoubleClickZoom: false, // false
          zoomControlOptions: {
            style: 2, // 2
            position: 4, // 4
          },
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
        });
        map.mapTypes.set("styled_map", styledMapType);
        map.setMapTypeId("styled_map");

        class Overlay extends google.maps.OverlayView {
          constructor(Lat, Lng) {
            super();
            this.LatLng = new google.maps.LatLng(Lat, Lng);
            this.div_ = null;
          }
          onAdd() {
            this.div_ = document.createElement("div");
            this.div_.className = "map-marker";
            this.div_.innerHTML =
              '<div class="middle-dot"></div>' +
              '<div class="signal"></div>' +
              '<div class="signal2"></div>';

            // Add the element to the "overlayLayer" pane.
            const panes = this.getPanes();
            panes.overlayLayer.appendChild(this.div_);
            // panes.overlayImage.appendChild(this.div_);
          }
          draw() {
            let point = this.getProjection().fromLatLngToDivPixel(this.LatLng);
            if (point) {
              this.div_.style.left = point.x + "px";
              this.div_.style.top = point.y + "px";
            }
          }
          onRemove() {
            if (this.div_) {
              this.div_.parentNode.removeChild(this.div_);
              this.div_ = null;
            }
          }
        }
        const overlay = new Overlay(myLatLng.lat, myLatLng.lng);
        overlay.setMap(map);
      });
    },
    invalidationNameCheck() {
      this.invalidation.name = !this.userName;
    },
    invalidationEmailCheck() {
      this.invalidation.email = !emailRegExp.test(this.emailAddr);
    },
    invalidationMsgCheck() {
      this.invalidation.message = !this.message;
    },
    send() {
      let formData = new FormData();
      let message = this.message;

      if (this.pending) return;

      this.invalidationNameCheck();
      this.invalidationEmailCheck();
      this.invalidationMsgCheck();

      if (
        this.invalidation.name ||
        this.invalidation.email ||
        this.invalidation.message
      )
        return;

      message = `
          From:
          ${this.userName} ${this.emailAddr}

          Subject:
          ${this.subject}

          Message:
          ${message}


          ----
          This email was sent from recreate.games.`;

      // from: hello@recreate.games
      // to: hello@recreate.games
      // subject: Recreate Games "[your-subject]"

      formData.append("from", "hello@recreate.games");
      formData.append("to", "hello@recreate.games"); //
      formData.append("replyTo", this.emailAddr);
      formData.append("subject", `Recreate Games "${this.subject}"`);
      formData.append("body", message);

      this.stateOfRequest = "loading";
      this.pending = true;

      sendEmail(formData)
        .then((res) => {
          if (res.data.code === 0) {
            this.success();
          } else {
            this.error();
          }
        })
        .catch(() => {
          this.error();
        });
    },
    showNotice() {
      this.isShowNotice = true;
      setTimeout(() => {
        this.isShowNotice = false;
        this.resetBtn();
      }, 3000);
    },
    success() {
      this.stateOfRequest = "done";
      this.showNotice();
      setTimeout(() => {
        this.resetForm();
      }, 2000);
    },
    error() {
      this.stateOfRequest = "error";
      this.showNotice();
    },
    resetBtn() {
      this.pending = false;
      this.stateOfRequest = "";
    },
    resetForm() {
      this.resetBtn();
      this.userName = "";
      this.emailAddr = "";
      this.subject = "";
      this.message = "";
    },
    getOffsetTop() {
      let introTop = 0;
      let aboutTop = document.querySelector("#intro").clientHeight;
      let careerTop = aboutTop + document.querySelector("#about").clientHeight;
      let contactTop =
        careerTop +
        document.querySelector("#careers").clientHeight +
        document.querySelector(".location").clientHeight;
      this.scrollTops = [introTop, aboutTop, careerTop, contactTop];
      this.halfWindowHeight = window.innerHeight / 2;
      this.$emit("update-scrolltops", this.scrollTops);
    },
    /*
     * 滚动防抖
     * */
    scrollDebounce(fn, wait) {
      let timeout = null;

      return function() {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(fn, wait);
      };
    },
    scrollHandle() {
      if (this.navClicked) {
        this.$emit("reset-nav-clicked-trigger");
      }
    },
    /*
     * 滚动节流
     * */
    scrollThrottle(fn, wait) {
      let startTime = Date.now();
      let timeout = null;

      return function() {
        if (this.navClicked) {
          return false;
        }
        let now = Date.now();
        clearTimeout(timeout);
        if (now - startTime >= wait) {
          startTime = now;
          fn();
        } else {
          timeout = setTimeout(fn, wait);
        }
      };
    },
    activeNavItem() {
      let offsetTop = scrollTop() + this.halfWindowHeight;
      for (let i = 0; i < this.scrollTops.length; i++) {
        if (
          (i < this.scrollTops.length - 1 &&
            offsetTop > this.scrollTops[i] &&
            offsetTop <= this.scrollTops[i + 1]) ||
          (i === this.scrollTops.length - 1 && offsetTop > this.scrollTops[i])
        ) {
          this.activatedNav = i;
          break;
        }
      }
      this.updateNavigation();
    },
    updateNavigation() {
      if (this.activatedNav === this.currentNav) return;
      this.$emit("update-nav", this.activatedNav);
    },

    /*    testSend() {
      this.stateOfRequest = 'loading';
      setTimeout( () => {
        // this.stateOfRequest = 'error';
        this.stateOfRequest = 'done';
        this.showNotice();
      }, 2000 )
      setTimeout( () => {
        this.stateOfRequest = '';
      }, 5000 )
    }*/
  },
};
</script>
