<template>
  <section class="not-founded">
    <div class="content">
      <div class="error-con">
        <h1 class="title">404</h1>
        <p class="descri">NOT FOUND</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Error",
  mounted() {
    document.querySelector('.nav-wrapper').className = 'nav-wrapper fixed';
    this.$emit('update-nav', null);
  },
  props: {
    careers: Array,
  },
}
</script>

