<template>
  <div>
    <section class="nav-wrapper" :class="{ fixed: isNavFixed }">
      <nav :class="{ 'hide-nav': foldNav }">
        <h1 class="logo-wrap" @click="updateNav(0, true)">
          <router-link id="logo" to="/zh/#intro"
            ><img alt="Recreate" src="../assets/img/Recreate.svg"
          /></router-link>
        </h1>
        <!--        <p class="nav-right"><a class="search icon-salient-search"></a></p>-->
        <div class="normal-nav">
          <p class="nav-right"><router-link to="/en">English</router-link></p>
          <ul class="nav-items">
            <li
              class="nav-item"
              @click="updateNav(0, true)"
              :class="{ active: activatedNavItem === 0 }"
            >
              <router-link to="/zh/#intro">首页</router-link>
            </li>
            <li
              class="nav-item"
              @click="updateNav(1, true)"
              :class="{ active: activatedNavItem === 1 }"
            >
              <router-link to="/zh/#about">关于我们</router-link>
            </li>
            <li
              class="nav-item"
              @click="updateNav(2, true)"
              :class="{ active: activatedNavItem === 2 }"
            >
              <router-link to="/zh/#careers">社会招聘</router-link>
            </li>
            <li
              class="nav-item"
              @click="updateNav(3, true)"
              :class="{ active: activatedNavItem === 3 }"
            >
              <router-link to="/zh/#careers_school">校园招聘</router-link>
            </li>
            <li
              class="nav-item"
              @click="updateNav(4, true)"
              :class="{ active: activatedNavItem === 4 }"
            >
              <router-link to="/zh/#contact">联系我们</router-link>
            </li>
          </ul>
        </div>

        <div class="nav-btn" @click="toggleMobileNav($event)">
          <a class="btn-lines"></a>
        </div>
      </nav>
      <div class="mobile-nav" :class="{ show: isShowMobileNav && foldNav }">
        <ul class="mobile-nav-list">
          <li class="mobile-nav-item" @click="updateNav(0, true)">
            <router-link to="/zh/#intro">首页</router-link>
          </li>
          <li class="mobile-nav-item" @click="updateNav(1, true)">
            <router-link to="/zh/#about">关于我们</router-link>
          </li>
          <li class="mobile-nav-item" @click="updateNav(2, true)">
            <router-link to="/zh/#careers">社会招聘</router-link>
          </li>
          <li class="mobile-nav-item" @click="updateNav(3, true)">
            <router-link to="/zh/#careers_school">校园招聘</router-link>
          </li>
          <li class="mobile-nav-item" @click="updateNav(4, true)">
            <router-link to="/zh/#contact">联系我们</router-link>
          </li>
          <li class="mobile-nav-lang">
            <router-link to="/en">English</router-link>
          </li>
        </ul>
      </div>
    </section>

    <router-view
      :careers="careers"
      :careers_school="careers_school"
      :navClicked="isClickedTrigger"
      :currentNav="activatedNavItem"
      v-on:img-loaded="imgLoaded"
      v-on:update-scrolltops="updateScrollTops"
      v-on:update-nav="updateNav"
      v-on:reset-nav-clicked-trigger="resetClickedTrigger"
    >
    </router-view>

    <footer>
      <div class="footer-con content">
        <div>
          © {{ new Date().getFullYear() }} Recreate Games. Source Technology
          Inc. 版权所有
        </div>
        <!-- <div>
          <img alt="" src="../assets/img/beian.png" class="beian-img" />
          <a href="beian.miit.gov.cn" target="_blank">沪ICP备2022007403号-1</a>
        </div> -->
      </div>
    </footer>
  </div>
</template>

<script>
import { scrollTop } from "@/utils/utils";
export default {
  name: "AppZh",
  data() {
    return {
      isShowMobileNav: false,
      foldNav: false,
      isNavFixed: false,
      activatedNavItem: 0,
      isClickedTrigger: false,
      navs: ["#intro", "#about", "#careers", "#careers_school", "#contact"],
      scrollingBinder: null,
      careers: [
        {
          id: 0,
          position: "游戏执行策划",
          place: "上海",
          time: "全职",
          descri:
            "不同平台的游戏版本体验需要保持一致，但是根据每个平台的特性和玩家习惯进行合适的修改也非常重要。你将会负责将游戏从 PC 端向主机端和移动端的需求修改，以及项目管理，最大程度上让游戏在其他平台上也获得原生平台的质感。",
          responsibility:
            "负责在移植游戏过程中提出针对平台特性的修改需求\n管理游戏开发进度",
          required:
            "热爱游戏\n有负责游戏跨平台的经验，能够独立推动项目进展\n对PC、主机、移动端游戏有深刻理解（一个或以上）\n能够根据不同平台玩家特性对游戏需求进行优化和修改\n具备良好的沟通协调能力和组织能力，执行力强，有责任心，有较强的抗压能力",
          plus: "参与过一款游戏的完整开发流程\n有游戏开发管理经验",
          materials: "个人简历",
        },
        {
          id: 33,
          position: "游戏数值策划",
          place: "上海",
          time: "全职",
          descri:
            "游戏内的数值平衡影响到玩家的核心游戏体验和游戏外的留存和付费，对游戏内的各个分项选择合理的数值模型对游戏体验影响极大。你将会负责游戏中角色差异性、经验模板、人物成长、商店定价、商品周期等游戏项目内的数学问题。",
          responsibility:
            "设定游戏的战斗数值和经济数值\n单局体验、成长体系、经济体系等体系的数值设定，并根据实际调试优化数值\n建立并不断优化已有的数值建模，用理论和实际测试保证数值平衡、可调\n负责调整数据、跟进版本实现，保证游戏开发质量",
          required:
            "热爱游戏\n对 PC、主机、移动端游戏有深刻理解（一个或以上）\n具备良好的沟通协调能力和组织能力，执行力强，有责任心，有较强的抗压能力\n对数值敏感，逻辑清晰，熟练运用各种数学公式构造数值发展曲线，具有较强的数学演算能力，能够搭建数学模型进行数值演算",
          plus:
            "参与过一款游戏的完整开发流程\n有过完整的游戏研发及运营经验，了解主流移动游戏的数值设计与方法",
          materials: "个人简历",
        },
        {
          id: 1,
          position: "概念设计总监",
          place: "上海",
          time: "全职",
          descri:
            "游戏美术风格的明确性和统一性至关重要。你将会负责游戏概念设计团队的整体风格制定；概念设计团队产出的规范；以及对整体美术风格的把控，确保所有资产的风格统一。",
          responsibility:
            "负责把握游戏整体视觉风格和概念设计\n整体负责并领导概念设计团队的工作，确保产出效率\n绘制气氛图，设计图并整合概念美术资源\n和制作人以及其他团队总监一同确保游戏最终质量\n为概念设计团队成员提供技术和职业规划上的指导\n和其他艺术总监一同持续定义并持续游戏独有的视觉风格",
          required:
            "美术基础扎实，对色彩，光影和材质感觉敏锐，想象力丰富，具有较高的审美能力\n对美术有独立的理解和体会，并愿意持续探索美术新风格\n具备良好的沟通协调能力和组织能力，执行力强，有责任心，有较强的抗压能力\n参与过至少一款游戏的开发完整流程\n英语沟通能力强（书面、口语、听力）",
          plus: "留学背景",
          materials: "个人简历\n个人作品集",
        },
        {
          id: 2,
          position: "角色概念设计师",
          place: "上海",
          time: "全职",
          descri:
            "好的角色的设计都对玩家代入感的生成有着至关重要的作用。你需要不断设计出符合游戏要求的人物形象与服饰、配饰。",
          responsibility:
            "制定游戏角色风格，输出角色原画设计\n执行概念设计总监的概念设计精神\n与 3D 美术共同创造游戏中的角色资产\n根据项目需求，完成高品质的 3D 角色\n关注行业动态，保证美术品质领先",
          required:
            "熟悉生物解剖结构\n美术基础扎实，对色彩和造型感觉敏锐，具有较高的审美能力\n具备协作精神，责任心强，擅长快速学习使用美术相关软件\n拥有卡通风格化角色设计能力",
          plus: "参与过一款游戏的完整开发流程\n熟练使用至少一个 3D 软件",
          materials: "个人简历\n个人作品集",
        },
        {
          id: 6,
          position: "场景概念设计师",
          place: "上海",
          time: "全职",
          descri:
            "高质量的场景对于提升体验来说至关重要，我们需要用有限的性能表现出最佳的画面。你需要设计出符合游戏设定的场景，寻找合适的制作途径，最大化地体现游戏场景的可玩性和互动性。",
          responsibility:
            "和概念设计总监一起定义游戏视觉风格和效果，产出明确、详细、实际可行的概念设计\n负责项目场景设计，输出符合游戏风格的场景\n独立绘制场景概念图，场景物件设计等",
          required:
            "有较好的想象力和场景氛围表达能力\n对场景色彩和光影效果及空间感有良好的把控能力\n具有将游戏玩法需求转换成视觉设计的能力：收集参考、调研、绘制剪影、细化、产出\n美术基础扎实，对色彩和造型感觉敏锐\n具备协作精神，责任心强，擅长快速学习使用美术相关软件",
          plus:
            "参与过一款游戏的完整开发流程\n熟练使用至少一个 3D 软件辅助设计",
          materials: "个人简历\n个人作品集",
        },
        {
          id: 4,
          position: "3D 资产制作总监",
          place: "上海",
          time: "全职",
          descri:
            "在优秀的概念设计出炉之后，3D 资产的执行至关重要。在 3D 资产制作团队中，美术规范的制定也将决定团队的产出效率。你将负责忠实地执行概念设计团队的设计意图，确保团队执行合理的美术规范，并且统一 3D 资产风格。",
          responsibility:
            "与概念设计总监合作，领导 3D 团队制作出符合概念设计的 3D 资产\n负责 3D 美术团队的管理工作，制定并把控美术规范\n负责游戏最终视觉效果\n确保游戏中角色与场景风格统一\n关注行业动态，保证美术品质领先\n为3D团队成员提供技术和职业规划上的指导",
          required:
            "熟悉 3D 美术工作流程，能够推动美术，技术美术等团队实现目标效果\n熟悉 UNITY 或 UNREAL ENGINE，并熟练掌握常用美术软件工具\n熟悉 3D 资产制作的全部流程（建模、绑定、渲染等）\n对美术有独立的理解和体会，并愿意持续探索美术新风格\n英语沟通能力强（书面、口语、听力）\n参与过至少一款游戏的完整开发流程",
          plus: "留学背景",
          materials: "个人简历\n个人作品集",
        },
        {
          id: 7,
          position: "资深 3D 场景美术",
          place: "上海",
          time: "全职",
          descri:
            "在游戏里，高质量的场景对于提升体验来说至关重要，我们需要用有限的性能表现出最佳的画面。你需要理解设计师的想法，寻找合适的制作途径，最大化地还原出概念场景。",
          responsibility:
            "将概念设计制作成 Unity 中的可用资产\n产出可直接供游戏使用的实时 3D 场景资产",
          required:
            "具有过硬的审美能力\n对灯光、材质有深刻的理解\n精通 3D 类设计软件\n能独立完成场景模型制作、UV 拆分、材质绘制、贴图烘焙\n具有 UNITY 或 UNREAL ENGINE 使用经验\n具有较强的故障排除能力和学习能力\n可以自己补全概念设计并产出符合风格的资产",
          plus:
            "具有离线渲染器使用经验\n有参与次时代游戏制作的经历\n具有 SHADER 制作经验\n具备建筑类学科背景",
          materials: "个人简历\n个人作品集",
        },
        {
          id: 5,
          position: "美术资产管理",
          place: "上海",
          time: "全职",
          descri:
            "美术资产生产流程的管理至关重要。你需要和各美术部门总监一同制定并优化美术资产的生产流程，创造工具和规范，解决美术团队遇到的生产力问题，确保美术资产的按时产出。",
          responsibility:
            "和各美术部门总监一同持续优化游戏美术资产的生产流程\n和概念设计总监、各美术部门总监和美术团队一起制定重要的时间节点，制定目标和要求\n帮助安排概念设计总监的时间表和优先级\n协调美术团队的沟通和同步所有人的工作进度\n维持美术团队的任务时间表，确保美术资产按计划产出\n帮助美术团队解决由美术工具和pipelines带来的生产力问题",
          required:
            "掌握基本 3D 软件\n熟悉 3D 美术工作流程，能够推动美术，技术美术等团队实现目标效果\n具备协作精神，责任心强，擅长快速学习使用美术相关软件",
          plus: "参与过一款游戏或影视作品的完整开发流程",
          materials: "个人简历",
        },
        {
          id: 8,
          position: "3D 动画设计师",
          place: "上海",
          time: "全职",
          descri:
            "游戏需要将画面从时间线上变得令人信服，这其中的关键便是动画。无论是角色、物体、动力学或粒子效果，优秀的动画甚至能将一个茶壶变得栩栩如生。我们希望你能够了解各种经典的动画表述方式，以及使用先进的技术将其表现出来。这个职位不止需要你在学校或之前你工作中所使用过的动画技法，我们还希望使用更多先进的技术改进动画效果或制作效率。",
          responsibility:
            "创造早期动画 2D 或 3D 分镜草图\n为 2D 或 3D 角色创造角色动画或物体动画\n对 3D 物品实施绑定，并与工程团队协调方案可行性\n参与动作捕捉流程",
          required:
            "热爱游戏\n熟悉线性和非线性动画创作流程\n熟悉人物角色和各种动物骨骼绑定\n了解如何处理体积塌陷，膨胀等动画常见技术问题\n了解行为树和引擎动作混合机制\n有极强的自驱和自学能力",
          plus:
            "参与过一款 AAA 级游戏完整从早期到上线流程\n熟悉 Unity 或 Unreal 引擎\n熟悉光学或惯性动捕流程\n熟悉任何 3D 工具的粒子或动力学系统",
          materials: "个人简历\n个人作品集",
        },
        {
          id: 18,
          position: "技术美术",
          place: "上海",
          time: "全职",
          descri: "",
          responsibility:
            "参与游戏材质、后处理以及特效的制作，优化渲染效果及效率，开发美术工具等",
          required:
            "有成熟的移动平台开发及优化经验\n熟练掌握渲染管线、常用光照模型、毛发渲染、粒子效果、后处理等\n熟悉 Unity URP/HDRP \n有良好的 Shader 编写能力，能够制作各类渲染风格的材质\n2年以上 Unity/Unreal 使用经验\n有良好的审美\n优秀的沟通能力、自学能力\n热爱游戏",
          plus:
            "熟悉 Houdini \n有程序化生成的相关制作经验\n可以根据需求开发美术所需的相关工具脚本\n完整参与过任意平台的游戏类产品并上线\n有良好编程基础",
          materials: "个人简历\n个人作品（不限于视频、可执行程序）",
        },
        {
          id: 26,
          position: "3D 渲染美术",
          place: "上海",
          time: "全职",
          descri: "",
          responsibility:
            "参与游戏角色和场景的效果图渲染工作\n负责从灯光、材质、动作、渲染到后期修图的整体流程",
          required:
            "精通 3D 类软件\n精通至少一款渲染器\n能独立完成从前期 3D 渲染到后期平面修图的完整工作\n有优秀的审美\n有良好的沟通协调能力，执行力强，有责任心",
          plus:
            "热爱游戏，有大量游戏经验\n有 Unity 或 Unreal Engine 的使用经验\n有从事影视制作的经验\n有扎实的美术基础或绘画功底",
          materials: "个人简历\n个人作品集",
        },
        {
          id: 15,
          position: "游戏音频设计师",
          place: "上海",
          time: "全职",
          descri:
            "在游戏世界里，优秀的游戏音频设计对于提升玩家的游戏体验来说至关重要。游戏音频设计师需要根据我们的游戏风格，设计出高质量的音频。",
          responsibility:
            "按照设计方案独立完成游戏音效制作\n通过录音设备、音频工作站（DAW）、效果器插件等软硬件设备，进行项目所需的录音创作编辑后期混音等\n负责在项目版本迭代中持续维护、调优，保证项目音频开发进度，解决游戏研发过程中产生的音频问题",
          required:
            "熟练掌握 FMOD 的使用\n至少做过一款上线游戏\n三年以上游戏音频或影视、动漫音频专业经验，熟知游戏音频各细分工种职能、游戏音频体系各子系统内容，在其中一项或多项有所专长\n对行业及产品有清晰了解，熟悉游戏产品及声音开发流程\n热爱游戏行业，对国内外主流游戏有相当的熟悉和理解\n优秀的音乐审美和赏析能力\n良好的沟通能力",
          plus: "作曲或录音专业相关教育背景\n有物理引擎动作游戏项目经验",
          materials: "个人简历\n个人作品集",
        },
        {
          id: 24,
          position: "UI 设计师",
          place: "上海",
          time: "全职",
          descri: "",
          responsibility:
            "参与游戏 UI 的设计和制作，包括整体界面、图标、动效等\n配合工程师完成游戏界面的实现，保证最终效果与设计品质一致",
          required:
            "有完整、独立的界面设计和执行经验\n精通设计类软件\n有优秀的审美\n有求知欲望和优秀的自学能力\n有良好的沟通协调能力，执行力强，有责任心",
          plus:
            "热爱游戏，有大量游戏经验\n熟悉 3D 类软件\n有 Unity 或 Unreal Engine 的使用经验\n精通动画制作（2D、3D 皆可）\n有扎实的美术基础或手绘功底",
          materials: "个人简历\n个人作品集",
        },
        {
          id: 34,
          position: "衍生品设计师（手办原型师）",
          place: "上海",
          time: "全职",
          descri: "",
          responsibility:
            "根据项目需求，负责手办模玩、场景配件的建模工作，能够独立、高效完成 3D 建模工作，准确呈现相关模型细节\n配合各个环节，调整模型表现方式，平衡设计效果与生产可行性要求\n能合理评估模型结构，独立完成拆件，提供生产端可用的成品文件",
          required:
            "本科及以上学历，艺术类院校雕刻、工业设计、玩具设计等相关\n造型能力强，熟练把握模型比例，有良好的色彩基础和审美能力\n有手办潮玩行业从业经验，对结构、材料生产运用以及产品开发流程有一定的认知\n熟练使用 ZBrush / 3D Max / Maya / Blender 等三维建模软件，有一定渲染基础，能使用 3D 打印技术打印模型",
          plus: "",
          materials: "个人简历\n个人作品集",
        },
        {
          id: 11,
          position: "游戏动画工程师",
          place: "上海",
          time: "全职",
          descri:
            "在游戏中动画质量同样重要，游戏动画工程师需要根据需求开发高级动画效果，增强游戏表现力。",
          responsibility: "参与开发程序化角色动画、物理计算性能优化等",
          required:
            "热爱游戏\n有良好的审美\n良好的数学和物理基础知识，对角色动作和物体运动有深刻的理解\n扎实的计算机学科基础知识\n拥有超过两年的 C# 编程经验和良好的编程习惯\n熟练掌握 Unity 引擎使用\n拥有计算机科学、数学或相关学士以上学位\n优秀的沟通能力、自学能力",
          plus:
            "有程序化动画制作经验\n有 Havok 引擎使用经验\n有参与开发物理引擎经验\n有 Unity ECS 和 C# Job System 使用经验\n有 3D 动画制作经验\n拥有移动平台性能优化与兼容性调试经验\n完整参与过任意平台的游戏类产品并上线",
          materials: "个人简历\n个人动画作品（不限于视频、代码、可执行程序）",
        },
        {
          id: 12,
          position: "图形图像工程师",
          place: "上海",
          time: "全职",
          descri:
            "在游戏中性能和图像质量同样重要，图形图像工程师需要在满足性能的前提下将画质推到极限。你将会和工程师、设计师共同推进游戏的图形图像技术，分析目前业务需求，以建立和改进图形图像相关代码库。",
          responsibility: "参与游戏渲染效果制作、优化游戏图形性能等",
          required:
            "热爱游戏\n有良好的审美\n扎实的计算机学科基础知识\n良好的数学和物理功底\n对渲染管线、常用光照模型、毛发渲染、粒子效果、后处理等有深刻的理解\n有良好的 Shader 编程能力，能根据项目需求独立开发和优化视觉效果\n2 年以上 Unity/Unreal 使用经验\n拥有计算机科学、数学或相关学士以上学位\n优秀的沟通能力、自学能力",
          plus:
            "熟悉任意一款 3D 软件\n拥有移动平台图像性能优化与兼容性调试经验\n完整参与过任意平台的游戏类产品并上线",
          materials: "个人简历\n个人作品（不限于视频、可执行程序）",
        },
        {
          id: 13,
          position: "资深游戏服务器开发工程师",
          place: "上海",
          time: "全职",
          descri:
            "资深游戏服务器工程师需要负责游戏服务器架构设计和开发，这需要你有多年的服务器实际项目经验。你将会和技术团队一同解决更深度的服务器线上问题，保证游戏稳定运行。资深游戏服务器工程师是团队可以依靠的技术人员。",
          responsibility:
            "负责游戏战斗及网关服务器的架构设计，功能开发维护\n快速定位解决服务器线上问题，保证服务稳定运行\n分析服务器瓶颈，结合游戏性能及稳定性进行调优",
          required:
            "大学本科及以上学历\n3年以上服务器开发经验，有上线游戏研发维护经验\n具有服务器性能瓶颈分析能力和优化经验\n熟练掌握 C++、Java、C# 及 Python 中的一种或者多种，有良好的编程习惯，配合团队编程规范的素养\n基础扎实，熟练掌握算法，数据结构相关知识，熟悉面对对象编程，具备合理运用设计模式的能力\n熟悉 Linux，有扎实的 Linux 编程环境经验，熟悉 TCP/IP 协议、UDP 协议、HTTP 协议\n熟练使用 NoSQL, 熟悉 NoSQL 数据设计模型\n能够熟练阅读英文技术文档\n较强的学习能力和责任心",
          plus:
            "Unity3d 客户端开发经验，性能优化经验\nNoSQL 性能调优经验，数据迁移经验\nAWS 开发运维经验",
          materials: "个人简历",
        },
        {
          id: 14,
          position: "游戏服务器开发工程师",
          place: "上海",
          time: "全职",
          descri:
            "游戏服务器工程师需要负责游戏服务器架构业务逻辑的开发和运维。你将会和技术团队一同解决服务器线上问题，保证服务稳定运行。",
          responsibility:
            "负责游戏战斗及网关服务器的架构业务逻辑开发维护\n快速定位解决服务器线上问题，保证服务稳定运行\n分析服务器瓶颈，结合游戏性能及稳定性进行调优",
          required:
            "大学本科及以上学历\n1年以上游戏服务器开发经验\n熟练掌握 C++、Java、C# 及 Python 中的一种或者多种，有良好的编程习惯配合团队编程规范的素养\n基础扎实，熟练掌握算法，数据结构相关知识，熟悉面对对象编程和常用设计模式\n熟悉 Linux 使用和开发环境，熟悉 TCP/IP 协议、UDP 协议、HTTP 协议\n熟练使用 NoSQL, 熟悉 NoSQL 数据设计模型\n能够熟练阅读英文技术文档\n较强的学习能力和责任心",
          plus: "上线游戏开发经验\nUnity3d 客户端开发经验\nAWS 开发运维经验",
          materials: "个人简历",
        },
        {
          id: 19,
          position: "游戏客户端工程师",
          place: "上海",
          time: "全职",
          descri:
            "工程实现是游戏成功的基石。开发工程师将会参与游戏开发的各种领域，如游戏逻辑、图形图像、用户界面、用人工智能改进游戏。开发工程师需要在各种硬件受限的情况下解决前人从未解决的难题，创造出健硕的工程并交付给百万人使用。",
          responsibility: "",
          required:
            "热爱游戏\n扎实的计算机学科基础知识，包括算法、数据结构和设计模式\n拥有超过两年的 C# 编程经验和良好的编程习惯\n熟练掌握 Unity 引擎使用\n广泛了解游戏开发的不同领域，如图形图像、玩法、测试方法等等\n拥有计算机科学、数学或相关学士以上学位\n优秀的沟通能力",
          plus:
            "完整参与过任意平台的游戏类产品并上线\n拥有 3D 软件的模型、材质、动画等模块完整使用经验\n拥有移动平台性能优化与兼容性调试经验\n超过 2 年的 C++ 实际项目经验\n为开源项目社区如 Github 或 Bitbucket 贡献过代码",
          materials: "个人简历\n个人项目代码样本",
        },
        {
          id: 32,
          position: "Xbox 游戏移植开发工程师",
          place: "上海",
          time: "全职",
          descri:
            "工程实现是游戏成功的基石。XBOX 游戏移植开发工程师将会参与游戏的各个模块从 PC 平台到 XBOX 平台的移植工作，如游戏逻辑、图形图像、用户界面等。XBOX 游戏移植开发工程师需要在 XBOX 主机的软硬件限制下解决一系列兼容性问题，创造出健硕的工程并交付给百万人使用。",
          responsibility:
            "负责游戏在 XBOX 平台的开发及移植工作\n负责游戏在 XBOX 平台上的性能监控及优化\n负责 XBOX 游戏业务逻辑开发\n协助美术/策划建立高效的游戏移植流程，完善并持续优化工具链",
          required:
            "热爱游戏\n扎实的计算机学科基础知识，包括算法、数据结构和设计模式\n拥有超过两年的 C# 编程经验和良好的编程习惯熟练掌握 Unity 引擎使用\n广泛了解游戏开发的不同领域，如图形图像、玩法、测试方法等等\n拥有计算机科学、数学或相关学士以上学位\n一年以上的主机游戏移植工作经历\n优秀的沟通能力",
          plus:
            "完整参与过其他平台的游戏类产品主机移植工作并上线\n拥有移动平台性能优化与兼容性调试经验\n超过 2 年的 C++ 实际项目经验\n为开源项目社区如 Github 或 Bitbucket 贡献过代码",
          materials: "个人简历\n个人项目代码样本",
        },
        {
          id: 21,
          position: "视频编解码工程师",
          place: "武汉/北京",
          time: "全职",
          descri:
            "这个职位需要创造一种跨平台解决方案，使视频质量更好的同时提供更小的尺寸。VR/AR 对于图像质量极高，目前我们可以回放双 4K 视频在 60 帧率，不远的未来还有 8K、12K、16K 视频。不只是帧率和分辨率的提升，还有色彩深度的加强。这些超高分辨率的视频将非常难以传输、存储和解压回放。为了实现完全沉浸式的体验，我们工程团队将面临各个方面严峻的技术挑战。",
          responsibility: "",
          required:
            "在图像编解码方向上有充足的经验，如 H.264/H.265/VP9/AV1\n有不同平台的编程经验，如 Windows, Linux, Android, iOS\n拥有 C/C++ 扎实的编程能力\n拥有 3 年以上相关行业从业经验\n优秀的分析，设计和 Debug 能力\n拥有计算机科学、数学或相关硕士以上学位\n参与过完整的任意平台应用软件开发",
          plus:
            "计算机或数学相关博士学位\n拥有一款 VR/AR 设备并使用超过一年\n为开源项目社区如 Github 或 Bitbucket 贡献过代码",
          materials: "个人简历\n个人项目代码样本",
        },
        {
          id: 27,
          position: "网络安全工程师",
          place: "上海",
          time: "全职",
          descri: "",
          responsibility:
            "定期进行安全风险评估、渗透测试、漏洞扫描，制定安全规程\n应急响应、调查取证安全事件，快速定位、解决安全问题",
          required:
            "全日制本科及以上学历，3 年以上互联网企业的信息安全从业经验\n扎实的计算机基础知识，精通常见 web、系统安全漏洞原理、利用方式和解决方案\n熟练使用常用安全工具，熟悉内网渗透技术和安全解决方案\n熟悉 Windows 或 Linux 操作系统，至少熟悉一门编程语言，能够开发自动化的分析工具\n良好的沟通、协作能力",
          plus:
            "拥有 CISSP、CISA、CISP、CIPT 证书\n有编写漏洞利用POC能力与Web漏洞扫描器开发经验",
          materials: "个人简历",
        },
        {
          id: 31,
          position: "游戏测试经理",
          place: "上海",
          time: "全职",
          descri: "该职位主要负责游戏 Party Animals 测试工作。",
          responsibility:
            "理解和分析游戏模式，制定测试计划，共同确定并实现产品质量目标\n设计测试方法，编写测试用例，构建和管理测试用例库\n执行测试，分析和定位测试中遇到的问题，协助解决问题\n形成可读性强的测试报告，对游戏软件质量进行评估\n统计和分析用户反馈的问题，协助开发人员解决用户问题",
          required:
            "熟悉 Steam 游戏平台或 XBOX, PlayStation 等主机平台\n扎实的计算机基础知识，熟悉游戏开发流程\n完整参与过任意平台游戏产品并上线\n有着丰富的多平台游戏软件性能测试和压力测试经验\n对游戏和软件质量充满热情\n拥有计算机科学、软件或相关学士以上学位",
          plus: "有外包测试管理经验\n有任意平台游戏编程经验\n熟悉 Unity 引擎",
          materials: "个人简历",
        },
        {
          id: 23,
          position: "产品测试工程师",
          place: "武汉",
          time: "全职",
          descri:
            "该职位将会负责 VR/AR 应用软件上线测试，保证应用的每个版本在不同的平台保持优异的性能和稳定性。",
          responsibility:
            "与产品⼈员沟通完善产品需求，参与需求分析和评审，协助制定测试方案和测试计划\n参与设计、执行测试用例，撰写测试报告\n负责产品发布前的实验室应用测试，包括部署测试及功能测试\n复现问题并验证功能，提出改进方案\n对异常测试结果及时记录和分析，及时反馈于研发",
          required:
            "2 年及以上测试/开发工作经验\n了解系统的基本原理和测试方法，善于从使用者角度考虑问题，拥有用户思维\n具备良好的问题分析能力、团队协作能力、沟通能力和中英文文档撰写能力\n拥有计算机、自动化或相关学士以上学位",
          plus: "掌握一种编程语言，如 C/C++、Java、Python 等\n白盒测试开展经验",
          materials: "个人简历",
        },
        {
          id: 28,
          position: "WEB 前端工程师",
          place: "上海",
          time: "全职",
          descri:
            "负责公司新产品的前端工作负责持续改进和优化产品功能及性能，追求极致的用户体验。",
          responsibility: "",
          required:
            "扎实的计算机学科基础知识，包括算法、数据结构和设计模式\n熟悉 HTML、HTML5、JavaScript、CSS/CSS3 等 Web 标准技术\n熟练掌握至少一种主流前端框架 ( Angular / Vue / React 等)\n良好的沟通能力、团队合作精神以及组织协调能力\n对 Web 前端技术有强烈兴趣，技术视野广阔，较强的学习能力和责任心\n拥有计算机科学、数学或相关学士以上学位",
          plus: "熟练使用 Linux 命令行工具",
          materials: "个人简历",
        },
        {
          id: 29,
          position: "Web 后端工程师（互动直播）",
          place: "上海",
          time: "全职",
          descri: "",
          responsibility: "",
          required:
            "本科及以上学历，2 年以上后端工作经验，负责互动直播系统的后端搭建\n熟练掌握两种以上后端语言（Go/C++/Java）\n熟悉一种以上数据库基本操作（SQL/MongoDB/Redis），理解其容灾原理，具备定位其性能问题的能力\n具备使用 Aliyun、AWS 或 GCP 等国内、外主流公有云的经验\n重视代码质量及研发规范\n具有较强的沟通能力，分析和解决问题的能力，具备良好的团队合作精神",
          plus:
            "有 ACM-ICPC 等程序设计竞赛参赛、获奖经历\n熟悉脚本语言，有搭建使用复杂系统的能力\n熟悉视频编解码以及相关技术\n熟悉 WebRTC 等实时通讯技术\n熟悉云计算/容器/云原生技术（OpenStack，LXD，runC，Kubernetes，cloud native stack）",
          materials: "个人简历",
        },
        {
          id: 30,
          position: "客户端工程师（互动直播）",
          place: "上海",
          time: "全职",
          descri: "",
          responsibility: "",
          required:
            "本科及以上学历\n熟练掌握 C++/C#，能够学习并使用 QT 等 framework 进行客户端开发\n了解视频编解码大致原理，能够学习并使用 FFmpeg 等 library 进行音视频处理\n重视代码质量及研发规范\n具有较强的沟通能力、分析和解决问题的能力，具备良好的团队合作精神",
          plus:
            "有 ACM-ICPC 等程序设计竞赛参赛、获奖经历\n使用过 QT 开发过中型以上的项目，有多媒体类软件开发经验\n精通视频编解码以及相关技术\n熟悉 WebRTC 等实时通讯技术",
          materials: "个人简历",
        },
        {
          id: 35,
          position: "游戏项目经理",
          place: "上海",
          time: "全职",
          descri: "",
          responsibility:
            "负责游戏开发项目的全生命周期管理，用专业的项目过程管理确保项目目标的达成\n负责项目的日常管理工作，包括项目计划制定、跟踪项目进度、关注项目质量等\n推动项目管理关键流程的落实，并从项目管理的观点和角度对团队内部流程进行优化和推进，提升团队整体效率\n有效预防和排除风险，及时发现和跟踪影响项目进度相关问题，积极推动与解决\n总结并沉淀相关项目管理实战经验，赋能项目和团队，提升整体效率\n善于学习跨专业、跨学科知识，迅速识别项目关键点和主路径",
          required:
            "3 年以上复杂项目管理经验，独立担任过全生命周期大项目的PM\n本科及以上学历\n精通游戏开发过程管理，对敏捷项目管理等有实际应用经验及深刻理解\n具有大局观，能有效地控制项目目标的达成。责任感强，工作积极主动，具有快速变化的能力\n有优秀的沟通和团队合作能力，有较强的抗压能力，有较强的执行力\n有较强的英文能力，可以无障碍和国外同事进行产品设计及技术交流沟通，愿意和海外团队一起工作，有海外工作经验优先",
          plus: "有 3D 制作经验优先\n有 PMP 证书优先",
          materials: "个人简历",
        },
        {
          id: 16,
          position: "商务拓展经理",
          place: "上海",
          time: "全职",
          descri:
            "这个职务负责与国内外各个媒体和平台、主播洽谈合作，并促成最终合作，推动业务增长。规划和沟通能力对于该职位来说尤为重要，商务拓展经理需要能够自我驱动以及对游戏事业有足够的热情。",
          responsibility:
            "市场拓展，对外商务合作与关系维护\n流量引入，内容输出，合作渠道维护\n与国内外各个媒体和平台、主播洽谈合作",
          required:
            "热爱游戏，对游戏行业有足够的理解\n至少二年的游戏行业的商务拓展从业经验，熟悉国内外各个媒体平台和主播\n熟练使用办公软件 和 Photoshop\n极强的沟通能力，能与管理团队、合作伙伴以及其他团队保持良好的沟通\n能够促成跨团队、公司的集体合作\n在高压下解决问题的能力\n中文，英文流利\n本科以上学位",
          plus: "留学经历\n托福 100+",
          materials: "个人简历",
        },
        {
          id: 10,
          position: "品牌运营",
          place: "上海",
          time: "全职",
          descri:
            "在游戏从内部推向市场的过程中，品牌运营的作用至关重要。品牌运营在游戏发布的各个阶段都需要让市场和玩家了解未来 Recreate games 所创作的产品。不仅是游戏发布上线运营后，品牌运营在上线前预热也同样重要。品牌运营将会和媒体一同将 Recreate games 游戏理念和产品传递给最终用户。",
          responsibility:
            "在媒体和社交平台为游戏产品建立持久的品牌信息投放渠道\n与策划，美术团队共同创造营销信息资产\n与策划，外部团队共同制定广告投放策略和资产\n与玩家社群达成良好的伙伴关系\n评估信息和广告投放数据表现",
          required:
            "热爱游戏\n拥有两年以上游戏或互联网品牌运营经验\n拥有完整从早期策划到最终落地的媒体策划和采购经验\n中文和英文流畅\n优秀的沟通和表达能力",
          plus:
            "拥有两年以上数字广告投放和监测经验\n拥有两年以上社交网络营销经验",
          materials: "个人简历",
        },
        {
          id: 17,
          position: "新媒体运营",
          place: "上海",
          time: "全职",
          descri:
            "对于一个成功的游戏来说，发行前后的宣传和对玩家群体的维护至关重要。除了游戏本身的品质之外，游戏运营的成果离不开宣传策略的制定，热点的抓取，和对玩家市场的充分了解。新媒体运营需要深入掌握玩家和相关社群的动向，保证游戏的常时热度，以及在热点出现时敏锐的反应速度。",
          responsibility:
            "负责各社交媒体的内容定期生产和发布\n维护各平台用户关系\n和平台主播、其他游戏厂商建立合作关系\n评估当前各平台数据表现\n制定短期和长期的游戏运营/宣传计划\n根据用户偏好和平台规则，给产品、美术团队提出内容生产建议",
          required:
            "优秀的英文口语、写作、阅读能力\n优秀的沟通和表达能力\n对各新媒体/社交平台有深入了解\n熟练使用至少一种视频编辑软件和一种修图软件",
          plus:
            "留学经历\n托福 100+\n数学、统计、产品、运营相关经历\n有社交媒体、短视频平台账号运营经验",
          materials: "个人简历",
        },
      ],
      careers_school: [
        {
          id: 100,
          position: "Gameplay 工程师",
          place: "上海",
          time: "校招/实习",
          descri:
            "工程实现是游戏成功的基石。作为 Gameplay 开发工程师，您将参与跨平台 Gameplay 系统的架构设计与功能实现，负责在 PC、主机端开发核心玩法，优化性能，攻克技术难题，确保各平台玩家获得流畅且一致的游戏体验。在硬件受限的情况下，您需解决前所未有的挑战，构建稳健的工程系统，服务千万玩家。",
          responsibility:
            "基于项目需求开发和优化游戏核心玩法模块，确保高质量的游戏体验\n与游戏策划、美术团队合作，完成相关功能的设计和实现，不断迭代改进玩法\n编写清晰高效的代码，进行调试及性能优化，确保游戏运行流畅稳定\n参与代码评审和技术讨论，分享经验并推动团队共同进步",
          required:
            "2025 届候选人：本科及以上学历，计算机科学、软件工程或相关专业\n2026 - 2027 届候选人：在读本科或研究生，能够利用课余时间实习，表现优秀者有留用转正机会\n熟悉至少一门 C 系语言，并精通至少一门面向对象编程语言，深入理解其核心原理和底层机制\n具备扎实的计算机基础知识，包括编程语言、编译原理、数据结构、算法、计算机组成、计算机网络及数据库等\n善于分析和沟通，逻辑清晰，有强烈的求知欲和优秀的学习能力",
          plus:
            "具备游戏开发项目经验或相关实习经历\n了解并使用过游戏引擎（如 Unity）进行开发",
          materials: "个人简历\n相关项目作品或 GitHub 代码链接（如有）",
        },
        {
          id: 101,
          position: "强化学习算法工程师",
          place: "上海",
          time: "校招/实习",
          descri:
            "算法创新是智能决策系统的核心驱动力。作为强化学习算法工程师，您将主导多智能体系统与复杂环境建模的算法设计与实现，开发游戏 AI、NPC 行为决策及动态环境交互算法，优化训练与推理性能，确保虚拟角色在不同场景中展现类人化智能表现。在资源受限条件下，您需突破传统方法边界，构建可扩展的强化学习框架，支撑千万级玩家的沉浸式交互体验。",
          responsibility:
            "在团队指导下，开发适用于游戏 AI 的强化学习模型\n将强化学习模型集成到游戏中，确保 AI 行为符合游戏设计需求\n评估强化学习模型的效果，优化其在游戏中的表现\n跟踪强化学习领域的最新研究成果，并将其应用于实际项目中",
          required:
            "2025 届候选人：本科及以上学历，计算机科学、人工智能、机器学习或相关专业\n2026 - 2027 届候选人：在读本科或研究生，能够利用课余时间实习，表现优秀者有留用转正机会\n熟悉至少一种编程语言，如 Python、C++ 或 Java\n了解强化学习的基本理论和常用算法，如 Q 学习、Sarsa、DQN、PPO 等\n有使用深度学习框架（如 TensorFlow、PyTorch）的经验\n具备良好的分析和解决问题的能力、良好的沟通能力和团队合作精神\n对新技术有强烈的求知欲和优秀的学习能力",
          plus:
            "有实际游戏项目的开发经历或实习经历\n接触学习过游戏开发引擎（如 Unity、Unreal Engine）\n在 GitHub 等平台上有开源项目贡献记录\n参加过知名编程比赛如 ACM / ICPC 并取得优异成绩\n在强化学习或游戏开发领域发表过论文",
          materials: "个人简历\n相关项目作品或 GitHub 代码链接（如有）",
        },
        {
          id: 102,
          position: "图形渲染工程师",
          place: "上海",
          time: "校招/实习",
          descri: "",
          responsibility:
            "参与高性能渲染引擎的开发和优化，提升渲染效率和图像质量，以满足高逼真度场景渲染需求\n研究和应用最新的图形图像技术，包括但不限于光线追踪、全局光照、材质建模等\n分析并优化渲染算法的性能，确保在不同硬件平台上的高效运行\n为其他部门提供图形渲染方面的技术支持，解决相关技术问题",
          required:
            "2025 届候选人：本科及以上学历，计算机、软件工程、自动化等相关专业\n2026 - 2027 届候选人：在读本科或研究生，能够利用课余时间实习，表现优秀者有留用转正机会\n熟练掌握 C++ 编程，熟悉常用算法和数据结构，具备良好的代码阅读、分析和设计能力\n熟悉计算机图形学原理，了解 2D / 3D 图形渲染技术，熟悉 OpenGL、DirectX 等图形 API\n具备良好的数学基础，能够理解和应用复杂的图形算法\n对新技术有强烈的求知欲，能够快速学习并应用于实际工作\n具备良好的沟通能力和团队协作精神，能够与他人协作完成任务",
          plus:
            "有图形渲染相关实习或项目经验者优先\n对图形渲染算法有深入研究，熟悉光线追踪、全局光照等技术者优先\n在 GitHub、GitLab 等平台有开源项目贡献者优先",
          materials: "个人简历\n相关项目作品或 GitHub/GitLab 代码链接（如有）",
        },
        {
          id: 103,
          position: "游戏服务器工程师",
          place: "上海",
          time: "校招/实习",
          descri:
            "游戏服务器工程师需要负责游戏服务器架构业务逻辑的开发和运维。你将会和技术团队一同解决服务器线上问题，保证服务稳定运行。",
          responsibility:
            "在团队指导下，参与游戏服务器功能模块的设计与开发\n与前端开发人员合作，进行功能模块的联调测试，确保服务器与客户端的有效通信\n协助进行服务器性能分析与优化，提升游戏的响应速度和稳定性\n参与定位并解决服务器端的问题，确保游戏的正常运行\n关注游戏服务器领域的新技术，提升自身技能并应用于实际项目",
          required:
            "2025 届候选人：本科及以上学历，计算机科学、软件工程或相关专业\n2026 - 2027 届候选人：在读本科或研究生，能够利用课余时间实习，表现优秀者有留用转正机会\n熟悉至少一种编程语言，如 C++、Java、C# 或 Python，具备良好的编码习惯\n掌握数据结构、算法、操作系统、计算机网络等基础知识\n了解 TCP / IP、UDP、HTTP 等网络协议的基本原理\n熟悉 Linux 操作系统，具备在 Linux 环境下进行开发的能力\n了解关系型数据库或 NoSQL 数据库的使用和设计\n具备较强的学习能力和责任心，能够适应新技术的快速变化",
          plus:
            "有实际游戏项目的开发经历或实习经验\n接触过游戏开发引擎（如 Unity、Unreal Engine）\n了解常见服务器性能调优方法和相关工具\n有使用云服务平台（如 AWS、Azure）的经验",
          materials: "个人简历\n相关项目作品或 GitHub 代码链接（如有）",
        },
        {
          id: 104,
          position: "移动端游戏工程师",
          place: "上海",
          time: "校招/实习",
          descri:
            "工程实现是移动游戏流畅体验的基础。移动端游戏工程师将主导 Android / iOS 双端核心模块开发，包括游戏逻辑、图形渲染及触控交互。需突破低功耗硬件限制，解决多机型适配、性能优化与热更新难题，构建高稳定工程架构，支撑百万日活玩家流畅体验。",
          responsibility:
            "在团队指导下，参与移动端游戏的功能模块开发，包括游戏逻辑、用户界面和动画效果等\n协助进行游戏性能分析与优化，确保在各种移动设备上运行流畅\n参与游戏在不同移动平台（如 iOS 和 Android）上的适配工作，确保兼容性和一致性\n协助定位并解决开发过程中的技术问题，确保游戏的稳定性和可靠性\n关注移动游戏开发领域的新技术和趋势，提升自身技能并应用于实际项目",
          required:
            "2025 届候选人：本科及以上学历，计算机科学、软件工程或相关专业\n2026 - 2027 届候选人：在读本科或研究生，能够利用课余时间实习，表现优秀者有留用转正机会\n熟悉至少一种编程语言，如 C#、C++ 或 Java，具备良好的编码习惯\n了解并使用过游戏开发引擎，如 Unity 或 Unreal Engine\n了解移动平台的开发流程和规范，熟悉 iOS 或 Android 平台的开发工具和环境\n具备良好的沟通能力和团队合作精神，能够与他人协作完成任务\n对新技术有强烈的求知欲和优秀的学习能力，能够快速适应技术变化",
          plus:
            "有实际移动端游戏开发项目的经历或实习经验\n了解移动平台性能优化与兼容性调试方法\n在开源社区（如 GitHub 或 Bitbucket）有代码贡献记录",
          materials: "个人简历\n相关项目作品或 GitHub 代码链接（如有）",
        },
        {
          id: 105,
          position: "游戏玩法策划",
          place: "上海",
          time: "校招",
          descri: "",
          responsibility:
            "参与游戏核心机制的设计，制定玩法规则和交互逻辑，确保游戏体验的趣味性与可玩性\n协助设计新的玩法模式，如战斗机制、互动系统、任务与目标等，提升游戏的用户粘性和市场竞争力\n协助玩法原型的搭建与测试，收集用户反馈，分析数据，不断优化玩法设计，提高游戏体验\n协助制定并调整游戏数值，确保不同玩法模式之间的公平性和策略深度\n与美术、程序等团队紧密合作，推动玩法设计的实现，确保玩法逻辑与美术、动画、物理交互相匹配\n研究市场竞品和玩家需求，结合趋势进行玩法创新，并提出可行的设计方案",
          required:
            "2025 届本科及以上学历，专业不限，游戏设计、计算机科学、艺术设计等相关专业优先\n对游戏设计有浓厚兴趣，熟悉各种游戏类型的玩法特点\n具备较强的逻辑思维和创新能力，能够设计有趣的互动体验\n熟练使用 Excel、Xmind、Visio 等工具，能够清晰表达设计思路\n良好的跨部门协作能力，能高效沟通并推动美术、程序等团队完成玩法实现\n具备数据驱动的设计思维，能够基于用户反馈和数据分析不断优化玩法",
          plus:
            "有游戏策划相关实习或项目经验者优先\n具备 Unity 或 Unreal Engine 开发基础，能进行简单的脚本编写和玩法原型搭建的候选人优先",
          materials: "个人简历\n相关作品或项目案例（如有）",
        },
        {
          id: 106,
          position: "助理产品经理",
          place: "上海",
          time: "校招",
          descri: "",
          responsibility:
            "协助收集用户需求和市场反馈，分析整理，形成产品需求文档\n参与产品功能设计，使用工具绘制产品原型图（如 Axure、Sketch），确保设计符合用户需求和产品定位\n协助跟进产品开发进度，与开发、测试等团队保持沟通，确保项目按计划推进\n参与用户调研和测试，收集用户反馈，提出产品改进建议\n协助监控产品使用数据，分析用户行为，为产品优化提供数据支持",
          required:
            "2025 届本科及以上学历，计算机、信息技术、市场营销、管理等相关专业\n具备良好的逻辑思维能力，能够进行有效的需求分析和问题解决\n具备良好的沟通表达能力，能够与团队成员和用户进行有效交流\n对新事物有强烈的好奇心，具备快速学习和适应能力\n具备团队合作精神，能够与不同部门协作完成任务\n熟悉常用的产品设计和项目管理工具，如 Axure、Sketch、JIRA 等",
          plus:
            "有产品相关实习经验者优先\n在校期间参与过产品设计或开发项目者优先\n具备一定的数据分析能力，能够使用 Excel 等工具进行数据处理",
          materials: "个人简历\n相关作品或项目案例（如有）",
        },
        {
          id: 107,
          position: "游戏数值策划",
          place: "上海",
          time: "校招",
          descri: "",
          responsibility:
            "在指导下，参与设计并搭建游戏的数值模型，包括战斗、成长和经济系统等\n负责游戏中数值的分析、设计、执行和调试工作，确保数值系统的合理性和平衡性\n负责数值相关表格的创建、配置、测试和调整等工作，确保数据的准确性和可维护性\n参与游戏中与数值关联紧密的系统设计，提供数值方面的支持和建议\n结合游戏运营数据分析，对游戏数值进行调优，提升玩家体验",
          required:
            "2025 届本科及以上学历，数学、统计学、计算机科学等相关专业优先\n对数字敏感，具备扎实的数学功底，了解概率论、统计分析等基础理论\n熟练使用 Excel 等办公软件，具备数据建模和分析能力\n具备优秀的逻辑思维能力和数据分析能力，能够设计和调整数值模型\n良好的沟通表达能力和团队协作精神，能够与他人协作完成任务\n对新知识有强烈的求知欲，能够快速学习并应用于实际工作\n热爱游戏行业，具有丰富的游戏经验，熟悉各类游戏的数值体系特征",
          plus:
            "有游戏数值策划相关实习或项目经验者优先\n熟悉 VBA、Matlab 等工具者优先\n具备数学、统计学等相关专业背景者优先",
          materials: "个人简历\n相关作品或项目案例（如有）",
        },
        {
          id: 108,
          position: "3D 手办设计师",
          place: "上海",
          time: "校招",
          descri: "",
          responsibility:
            "根据项目需求，负责手办模玩、场景配件的建模工作，能够独立、高效完成 3D 建模工作，准确呈现相关模型细节\n配合各个环节，调整模型表现方式，平衡设计效果与生产可行性要求\n能合理评估模型结构，独立完成拆件，提供生产端可用的成品文件",
          required:
            "本科及以上学历，艺术类院校雕刻、工业设计、玩具设计等相关专业\n造型能力强，熟练把握模型比例，有良好的色彩基础和审美能力\n有手办潮玩行业从业经验，对结构、材料生产运用以及产品开发流程有一定的认知\n熟练使用 ZBrush、3D Max、Maya、Blender 等三维建模软件，有一定渲染基础，能使用 3D 打印技术打印模型",
          plus: "",
          materials: "个人简历\n个人作品集或项目案例（如有）",
        },
        {
          id: 109,
          position: "游戏概念设计师",
          place: "上海",
          time: "校招",
          descri: "",
          responsibility:
            "根据游戏设定，设计并绘制角色、场景、道具等元素的概念图，为后续开发提供视觉参考\n与团队成员协作，确保概念设计符合游戏整体美术风格和质量标准\n与策划、3D 美术等团队成员沟通，确保设计意图准确传达并有效实现",
          required:
            "2025 届本科及以上学历，美术、设计等相关专业\n具备扎实的美术功底，对色彩、构图、光影有深入理解\n熟练使用 Photoshop 等绘画软件，了解基本的 3D 软件操作（如 Blender、3ds Max、Maya 等）\n具备良好的设计能力，能够根据需求进行创新设计\n对新知识有强烈的求知欲，能够快速学习并应用于实际工作\n具备良好的沟通能力和团队协作精神，能够与他人协作完成任务\n热爱游戏行业，对游戏美术设计有浓厚兴趣",
          plus:
            "有游戏概念设计相关实习或项目经验者优先\n能够适应不同美术风格的设计需求，如欧美写实、日系二次元等\n了解并能简单操作基本的 3D 软件，具备 3D 辅助设计能力",
          materials: "个人简历\n个人作品集或项目案例（如有）",
        },
        {
          id: 110,
          position: "3D 场景设计师",
          place: "上海",
          time: "校招",
          descri: "",
          responsibility:
            "根据项目需求，制作高质量的 3D 场景模型，包括建筑、道具等元素\n为模型添加材质和贴图，增强场景的真实感与视觉表现力\n在引擎中进行场景的搭建和渲染，营造出符合项目要求的氛围和效果\n与原画设计师、程序员等团队成员紧密合作，确保场景设计符合整体风格和技术要求\n根据反馈，对场景进行优化和调整，提升性能和视觉效果",
          required:
            "2025 届本科及以上学历，美术、设计、动画等相关专业\n熟练使用 3D 设计软件，如 Maya、3ds Max 或 Blender\n具备良好的美术功底，对构图、色彩、光影有深入理解\n对新技术和工具有强烈的求知欲，能够快速掌握并应用\n具备良好的沟通能力和团队协作精神，能够与他人协作完成任务\n工作态度积极，具备高度的责任感和时间管理能力",
          plus:
            "有 Unity 或 Unreal Engine 使用经验者优先\n具备离线渲染器使用经验者优先\n具有 Shader 制作经验者优先",
          materials: "个人简历\n个人作品集或项目案例（如有）",
        },
        {
          id: 111,
          position: "3D 角色设计师",
          place: "上海",
          time: "校招",
          descri: "",
          responsibility:
            "根据原画设计，制作高质量的 3D 角色模型，包括角色、怪物等\n为角色模型绘制贴图，确保材质和纹理的真实感和艺术效果\n根据游戏性能需求，对模型进行优化，确保在不同平台上的流畅运行\n与原画设计师、动画师和程序开发团队沟通，确保角色设计的可实现性和一致性",
          required:
            "2025 届本科及以上学历，美术、动画、数字媒体等相关专业\n具备扎实的美术功底，对人体结构、比例、解剖学有深入理解\n熟练使用 3D 建模软件（如 Maya、3ds Max、ZBrush 等）和绘图软件（如 Photoshop）\n具备良好的造型设计能力，能够根据原画进行高质量的 3D 建模\n对新技术和工具有强烈的求知欲，能够快速学习并应用于实际工作\n具备良好的沟通能力和团队协作精神，能够与他人协作完成任务\n热爱游戏行业，对游戏角色设计有浓厚兴趣",
          plus:
            "有游戏项目开发经验者优先\n能够适应不同美术风格的设计需求，如写实、卡通等\n了解角色动画原理，能够为动画制作提供支持",
          materials: "个人简历\n个人作品集或项目案例（如有）",
        },
      ],
      resizeBinder: null,
      scrollTops: [],
      imgsLoaded: false,
    };
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.scrollingBinder, false);
    window.removeEventListener("resize", this.resizeBinder, false);
    document.removeEventListener("click", this.hideMobileNav, false);
  },
  mounted() {
    this.resizeBinder = this.foldMobileNav.bind(this);
    this.scrollingBinder = this.pageScrolling.bind(this);
    document.addEventListener("scroll", this.scrollingBinder, false);
    window.addEventListener("resize", this.resizeBinder, false);
    document.addEventListener("click", this.hideMobileNav, false);
    this.foldMobileNav();
  },
  methods: {
    toggleMobileNav(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isShowMobileNav = !this.isShowMobileNav;
    },
    hideMobileNav() {
      if (this.isShowMobileNav) this.isShowMobileNav = false;
    },
    foldMobileNav() {
      this.foldNav = window.innerWidth <= 800;
    },
    pageScrolling() {
      let _scrollTop = scrollTop();
      if (_scrollTop > 0 && !this.isNavFixed) {
        this.isNavFixed = true;
      } else if (_scrollTop === 0 && this.isNavFixed) {
        this.isNavFixed = false;
      }
    },
    updateScrollTops(arr) {
      this.scrollTops = arr;
    },
    imgLoaded() {
      // if(!this.imgsLoaded){
      //   this.imgsLoaded = true;
      let itemId = this.navs.findIndex((hash) => hash === this.$route.hash);
      if (itemId > 0) {
        this.scrollTo(this.scrollTops[itemId]);
      }
      // }
    },
    scrollTo(y) {
      document.documentElement.scrollTop = document.body.scrollTop = y;
    },
    updateNav(itemId, isClicked) {
      this.isClickedTrigger = isClicked || false;
      this.activatedNavItem = itemId;
      if (isClicked && this.$route.hash === this.navs[itemId]) {
        // document.querySelector(this.navs[itemId]).scrollIntoView();
        this.scrollTo(this.scrollTops[itemId]);
      }
    },
    resetClickedTrigger() {
      this.isClickedTrigger = false;
    },
  },
};
</script>

<style scoped></style>
