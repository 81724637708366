import axios from "axios";

export function sendEmail(formData) {
  return axios({
    method: "post",
    url:
      // "https://wekb0rx9b3.execute-api.ap-northeast-1.amazonaws.com/Prod/mailer",
      "https://api.mailer.recreate.games/send-email",
    // "https://careers.api.recreategames.com.cn/send-email",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
